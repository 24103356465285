import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
	Container,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
import VideoUpload from "../VideoUpload";
import {useNavigate} from "react-router-dom";

const About2 = () => {
	const { setUser, user } = useAuth();
	const navigate = useNavigate();

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}


	return (
		<>
			<Helmet>
				<title>Build Your DNA Profile | mygolfdna.com</title>
				<meta name="description" content="build your dna profile"/>
			</Helmet>
			<div className="mt-4">
				<Container>
					<Row>
						<div className="text-center h1 text-uppercase monument-font">Build Your DNA Profile</div>
						<Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
							<p className="text-center text-black-50">Take five minutes and tell us about you and your game, show us some swings and setup your consult. Piece of cake!</p>
							<div className="container d-flex justify-content-center align-items-center">

								<div className="progresses">


									<div className="steps bg-dark-blue">
										<span className="font-weight-bold"><i className="bi bi-check"/></span>
									</div>
									<div className="small d-none d-sm-block">Shipping</div>

									<span className="line bg-dark-blue"/>

									<div className="steps bg-dark-blue">
										<span className="font-weight-bold">2</span>
									</div>
									<div className="small d-none d-sm-block">About</div>


									<span className="line"/>

									<div className="steps">
										<span className="font-weight-bold">3</span>
									</div>
									<div className="small d-none d-sm-block">Upload</div>
									<span className="line"/>

									<div className="steps">
										<span className="font-weight-bold">4</span>
									</div>
									<div className="small d-none d-sm-block">Consult</div>

								</div>

							</div>
							<p className="mt-4 text-center text-secondary font-weight-bold">Tell us about your game</p>
							<Formik
								enableReinitialize={true}
								initialValues={{injuries: '', handicap: '', training: '', frustrates: '', rounds: '', practice: '', yearsPlayed: '', equipment: '', goals: ''}}

								validate={(values) => {
									const errors = {};
									if (!values.injuries) {
										errors.injuries = "Required";
									}
									if (!values.rounds) {
										errors.rounds = "Required";
									}
									if (!values.yearsPlayed) {
										errors.yearsPlayed = "Required";
									}
									if (!values.practice) {
										errors.practice = "Required";
									}

									if (!values.handicap) {
										errors.handicap = "Required";
									}
									if (!values.training) {
										errors.training = "Required";
									}
									if (!values.equipment) {
										errors.equipment = "Required";
									}
									if (!values.frustrates) {
										errors.frustrates = "Required";
									}
									if (!values.goals) {
										errors.goals = "Required";
									}

									return errors;
								}}
								onSubmit={(values, { setSubmitting, setErrors }) => {
									setTimeout(() => {
										API.put("wizard", {
											handicap: values.handicap,
											rounds: values.rounds,
											yearsPlayed: values.yearsPlayed,
											practice: values.practice,
											equipment: values.equipment,
											training: values.training,
											injuries: values.injuries,
											frustrates: values.frustrates,
											goals: values.goals,
										})
											.then((res) => {
												navigate('/wizard/upload', { replace: true })
												toast.success('Nice! Almost done!', {
													position: toast.POSITION.TOP_RIGHT,
												});
												setSubmitting(false);
											})
											.catch((error) => {
												setSubmitting(false);
												if (error.response) {
													notify(error.response.data.message);
												}
												if (
													error.response &&
													error.response.data.errors &&
													error.response.data.errors.length
												) {
													const result = error.response.data.errors.reduce(function (
															map,
															obj
														) {
															map[obj.property] = obj.message;
															return map;
														},
														{});
													setErrors(result);
												}
											});
									}, 400);
								}}
							>
								{({
										values,
										errors,
										touched,
										handleChange,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
									<>

										<Form noValidate className="form-group" onSubmit={handleSubmit}>
											<Row>
												<Col sm={12} md={6}>
													<Form.Group className="form-floating mb-3">
														<Form.Control
															className=""
															type="text"
															name="handicap"
															id="handicap"
															onChange={handleChange}
															value={values.handicap}
															isInvalid={errors.handicap && touched.handicap}
															isValid={!errors.handicap && touched.handicap}
														/>
														<Form.Label className="" htmlFor="handicap">Current Handicap</Form.Label>
														<div className="invalid-feedback">{errors.handicap}</div>
													</Form.Group>
												</Col>
												<Col sm={12} md={6}>
													<Form.Group className="form-floating mb-3">
														<Form.Select
															aria-label="rounds"
															name="rounds"
															id="rounds"
															onChange={handleChange}
															value={values.rounds}
															isInvalid={errors.rounds && touched.rounds}
															isValid={!errors.rounds && touched.rounds}
														>
															<option value="" disabled>Select your option</option>
															<option value="0-4">0-4</option>
															<option value="4-10">4-10</option>
															<option value="10+">10+</option>
														</Form.Select>

														<Form.Label className="" htmlFor="rounds">Rounds Per Month</Form.Label>
														<div className="invalid-feedback">{errors.rounds}</div>
													</Form.Group>
												</Col>

												<Col sm={12} md={6}>
													<Form.Group className="form-floating mb-3">
														<Form.Select
															aria-label="yearsPlayed"
															name="yearsPlayed"
															id="yearsPlayed"
															onChange={handleChange}
															value={values.yearsPlayed}
															isInvalid={errors.yearsPlayed && touched.yearsPlayed}
															isValid={!errors.yearsPlayed && touched.yearsPlayed}
														>
															<option value="" disabled>Select your option</option>
															<option value="0-11 months">0-11 months</option>
															<option value="1-3 years">1-3 years</option>
															<option value="4-10 years">4-10 years</option>
															<option value="11-20 years">11-20 years</option>
															<option value="21+">21+ years</option>
														</Form.Select>

														<Form.Label className="" htmlFor="yearsPlayed">Years Played</Form.Label>
														<div className="invalid-feedback">{errors.yearsPlayed}</div>
													</Form.Group>
												</Col>
												<Col sm={12} md={6}>
													<Form.Group className="form-floating mb-3">
														<Form.Select
															aria-label="practice"
															name="practice"
															id="practice"
															onChange={handleChange}
															value={values.practice}
															isInvalid={errors.practice && touched.practice}
															isValid={!errors.practice && touched.practice}
														>
															<option value="" disabled>Select your option</option>
															<option value="0-5 times a month">0-5 times a month</option>
															<option value="5-10 times a month">5-10 times a month</option>
															<option value="10+ times a month">10+ times a month</option>
														</Form.Select>

														<Form.Label className="" htmlFor="practice">How often do you practice</Form.Label>
														<div className="invalid-feedback">{errors.practice}</div>
													</Form.Group>
												</Col>
												<Col md={12}>
													<Form.Group className="form-floating mb-3">
														<Form.Select
															aria-label="equipment"
															name="equipment"
															id="equipment"
															onChange={handleChange}
															value={values.equipment}
															isInvalid={errors.equipment && touched.equipment}
															isValid={!errors.equipment && touched.equipment}
														>
															<option value="" disabled>Select your option</option>
															<option value="fitted">fitted</option>
															<option value="not-fitted">not fitted</option>
														</Form.Select>

														<Form.Label className="" htmlFor="equipment">Which best describes your current equipment?</Form.Label>
														<div className="invalid-feedback">{errors.equipment}</div>
													</Form.Group>
												</Col>
												<Col md={12}>
													<Form.Group className="form-floating mb-3">
														<Form.Control
															className=""
															type="text"
															name="training"
															id="training"
															onChange={handleChange}
															value={values.training}
															isInvalid={errors.training && touched.training}
															isValid={!errors.training && touched.training}
														/>
														<Form.Label className="" htmlFor="training">Do you own any training aids, if yes, which ones?</Form.Label>
														<div className="invalid-feedback">{errors.training}</div>
													</Form.Group>
												</Col>
												<Col md={12}>
													<Form.Group className="form-floating mb-3">
														<Form.Control
															className=""
															type="text"
															name="injuries"
															id="injuries"
															onChange={handleChange}
															value={values.injuries}
															isInvalid={errors.injuries && touched.injuries}
															isValid={!errors.injuries && touched.injuries}
														/>
														<Form.Label className="" htmlFor="injuries">Do you have any injuries, if yes, please explain</Form.Label>
														<div className="invalid-feedback">{errors.injuries}</div>
													</Form.Group>
												</Col>
												<Col md={12}>
													<Form.Group className="form-floating mb-3">
														<Form.Control
															className=""
															type="text"
															name="frustrates"
															id="frustrates"
															onChange={handleChange}
															value={values.frustrates}
															isInvalid={errors.frustrates && touched.frustrates}
															isValid={!errors.frustrates && touched.frustrates}
														/>
														<Form.Label className="" htmlFor="frustrates">What frustrates you most about golf?</Form.Label>
														<div className="invalid-feedback">{errors.frustrates}</div>
													</Form.Group>
												</Col>
												<Col md={12}>
													<Form.Group className="form-floating mb-3">
														<Form.Control
															className=""
															type="text"
															name="goals"
															id="goals"
															onChange={handleChange}
															value={values.goals}
															isInvalid={errors.goals && touched.goals}
															isValid={!errors.goals && touched.goals}
														/>
														<Form.Label className="" htmlFor="goals">Tell me what your main goals are with golf</Form.Label>
														<div className="invalid-feedback">{errors.goals}</div>
													</Form.Group>
												</Col>
											</Row>
											<div className="d-grid">
												<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
													{isSubmitting ? (
														<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
														</>
													) : (
														"Next Step"
													)}
												</Button>
											</div>
										</Form>
									</>
								)}
							</Formik>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default About2;
