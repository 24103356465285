import {useAuth} from "./AuthUser";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import moment from "moment";
import UploadAndDisplayImage from "./pages/Account/UploadAndDisplayImage";
import './assets/css/profile-nav.css';
import ProgressTracker from "./pages/ProgressTracker";
import MyLessons from "./components/MyLessons";
import RecommendedVideos from "./components/RecommendedVideos";
import NewReleases from "./components/NewReleases";
import Button from "react-bootstrap/Button";
import ProfileImage from "./components/ProfileImage";

function DNAProfile() {
	let auth = useAuth();
	function SubscriptionOverlay() {
		return (
			<div className="position-absolute top-50 start-50 translate-middle" style={{zIndex: 1}}>
				<div className="text-center d-none d-md-block">
					<div><i className="fa-solid fa-lock text-orange" style={{fontSize: "3rem"}}/></div>
					<div className="h2 text-white text-center">Subscribe to use the Progress Tracker</div>
					<div><Button href="/pricing" className="text-uppercase font-weight-bold text-white" variant="secondary" size="lg">Get Access Now</Button></div>
				</div>
				<div className="text-center d-sm-block d-md-none">
					<div><i className="fa-solid fa-lock text-orange" style={{fontSize: "2rem"}}/></div>
					<div className="h5 text-white text-center">Subscribe to use the Progress Tracker</div>
					<div><Button style={{width: 160}} href="/pricing" className="text-uppercase font-weight-bold text-white" variant="secondary" size="sm">Get Access Now</Button></div>
				</div>
			</div>
		);
	}
	return (
		<>
		<Helmet>
			<title>DNA Profile | MyGolfDNA.com</title>
			<meta name="description" content="Your DNA Profile"/>
		</Helmet>
		<div className="">
			<div style={{height: 'auto', color: "#fff", backgroundColor: '#1b2631', minHeight: 200}} className="">
				<div className="text-center p-2 pb-1">
					{/*<UploadAndDisplayImage className="object-fit-cover flex-shrink-0 image-uploader"/>*/}
					<ProfileImage size={80} textSizeRatio={1.75} text={auth.user.email}/>
					<h5 className="mt-3">{auth.user.firstName ? auth.user.firstName : auth.user.email}</h5>
					<p className="small" style={{fontSize: '14px'}}><span style={{marginRight: 10}}><strong>Handicap</strong>: {auth.user.handicap}</span> <span style={{marginRight: 10}}><strong>Joined</strong>: {moment(auth.user.created_at).utc().format('LL')}</span> <strong>Membership</strong>: {auth.user.planType ? '' : 'Free' } <span className="text-capitalize">{auth.user.planType ? <span className="text-orange"> {auth.user.planType} </span>: <a href="/pricing" className="text-warning text-decoration-none"  style={{marginLeft: 2}}>(Upgrade)</a> }</span></p>
				</div>
			</div>
			<Container>
				<Row>
					<Col sm={12} md={12} lg={6}>
						<div className="mt-4">
						<MyLessons/>
						</div>
					</Col>
					<Col sm={12} md={12} lg={6}>
						<div className="mt-4">
						<Card className="lesson-progress-tracker">
							<Card.Header as="h6"><div className="hstack gap-3">
								<div className="">Progress Tracker</div>
							</div>
							</Card.Header>
							<Card.Body>
								{auth.user.planType ? <ProgressTracker/> :
									<div className="img-gradient-progress-tracker container-fluid p-0">
									<div className="test-div-inner-progress-tracker">
										<ProgressTracker/>
										<SubscriptionOverlay/>
									</div>
								</div>}
							</Card.Body>
						</Card>
						</div>
						</Col>
				</Row>
				<Row>
					<Col>
						<Card className="mt-4">
							<Card.Header as="h5"><div className="hstack gap-3">
								<div className="">Recommended Videos</div>
							</div>
							</Card.Header>
							<Card.Body>
								<RecommendedVideos/>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card className="mt-4">
							<Card.Header as="h5"><div className="hstack gap-3">
								<div className="">New Releases</div>
							</div>
							</Card.Header>
							<Card.Body>
								<NewReleases/>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
			</>
	);
}

export default DNAProfile;
