import React, { useState, useContext, useEffect } from "react";
import API from "./api";
import Cookies from "js-cookie";
const AuthUserContext = React.createContext();

// So we don't conflict with your localhost
const cookieName = "__mygolfdna__";
const currentUserCookieName = "__mygolfdna_current_user__";

export const AuthProvider = ({ children }) => {
	const cookieCurrentUser = Cookies.get(currentUserCookieName) ? JSON.parse(Cookies.get(currentUserCookieName)) : null;
	const [user, setUser] = useState(cookieCurrentUser || false);

	useEffect(() => {
		API.get("/accounts/me").then(
			(res) => {
				Cookies.set(currentUserCookieName, JSON.stringify(res.data));
				//setUser(res.data);
				console.log('auth: ', res.data);
			},
			(error) => {
				Cookies.remove(currentUserCookieName);
			}
		);
	}, [user]);

	const signout = (callback) =>
		API.get("auth/logout").then(() => {
			setUser(null);
			return callback();
		});


	return (
		<AuthUserContext.Provider
			value={{ user, setUser, signout }}
		>
			{children}
		</AuthUserContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthUserContext);
};
