import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
	Container,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
import VideoUpload from "../VideoUpload";

const WizardUpload = () => {
	const { setUser, user } = useAuth();

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}


	return (
		<>
			<Helmet>
				<title>Build Your DNA Profile | mygolfdna.com</title>
				<meta name="description" content="build your dna profile"/>
			</Helmet>
			<div className="mt-4">
				<Container>
					<Row>
						<div className="text-center h1 text-uppercase monument-font">Build Your DNA Profile</div>
						<Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
							<p className="text-center text-black-50">Time to show us your swings.</p>
							<div className="container d-flex justify-content-center align-items-center">

								<div className="progresses">


									<div className="steps bg-dark-blue">
										<span className="font-weight-bold"><i className="bi bi-check"/></span>
									</div>
									<div className="small d-none d-sm-block">Shipping</div>

									<span className="line bg-dark-blue"/>

									<div className="steps bg-dark-blue">
										<span className="font-weight-bold"><i className="bi bi-check"/></span>
									</div>
									<div className="small d-none d-sm-block">About</div>


									<span className="line bg-dark-blue"/>

									<div className="steps">
										<span className="font-weight-bold">3</span>
									</div>
									<div className="small d-none d-sm-block">Upload</div>
									<span className="line"/>

									<div className="steps">
										<span className="font-weight-bold">4</span>
									</div>
									<div className="small d-none d-sm-block">Consult</div>

								</div>

							</div>

							<div className="mt-4">
							<VideoUpload
								redirectTo="/wizard/consult"
								consult={1}
							/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default WizardUpload;
