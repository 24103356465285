import React, {useEffect, useLayoutEffect, useState} from "react";
import {CreditCard, PaymentForm} from "react-square-web-payments-sdk";
import {
	Container,
	Row,
	Col,
	Modal,
} from "react-bootstrap";
import api from "../../api";
import {useAuth} from "../../AuthUser";
import {useNavigate, Navigate, useLocation, useParams} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MoneyBackImg from "../../assets/img/90day.png";
import API from "../../api";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Plan = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [plan, setPlan] = useState([])

	const { setUser, user } = useAuth();
	const navigate = useNavigate();
	let query = useQuery();
	let { planId } = useParams();

	useEffect(() => {
		setTimeout(() => {
			API.get(`plan/${planId}`)
				.then(response => {
					setPlan(response.data);
					return response.data;
				})

		}, 0);
	}, [planId])


	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

const cadenceMap = {
	'MONTHLY': 'month',
	'ANNUAL': 'annual',
}

function insertDecimal(num) {
	return Number((num / 100).toFixed(2));
}

const cadence = cadenceMap[plan.cadence];

	return (
		<Container>
			<Row>
				<Col sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
					<Row className="align-items-center mt-4">
						<Col><h2 className="text-secondary monument-font">{plan.planName}</h2>
						</Col>
					</Row>
					<Row className="align-items-center">
					<Col>
						<div className="d-grid gap-2 mt-4 mb-4">
							<div dangerouslySetInnerHTML={{__html: plan.description}}>
							</div>
							<div className="d-flex align-items-center gap-1">
								<div className="h1">
									${insertDecimal(plan.amount)}
								</div>
								<small className="">/{cadence}</small>
							</div>

						</div>
					</Col>
					<Col>
						<div className="p-0">
							<img
								className="mt-0"
							src={MoneyBackImg}
							width={200}
							alt="moneyback"
						/></div>

					</Col>
					</Row>
					<h5 className="mb-3">Your Billing Details</h5>
					<PaymentForm
						applicationId="sq0idp--OAER2pSUlUDNqUV8uKXFA"
						cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
							handleShow();
							await api.post("/pay/create", {
								sourceId: token.token,
								planId,
								}).then(resp => {
									handleClose();
									navigate("/thankyou", { replace: true });
							}).catch(err => {
								handleClose();
								notify(err.response.data.message);
							})
						}}
						locationId={process.env.NODE_ENV === 'production' ? "4F2E7F3QP3YD5" : "L36AG5ZKTWKGM"}
					>
						<CreditCard
							buttonProps={{
								css: {
									backgroundColor: "#157729",
									fontSize: "14px",
									color: "#fff",
									"&:hover": {
										backgroundColor: "#1f8c44",
									},
								},
							}}
						/>
					</PaymentForm>
					<div className="mt-4 text-black-50">
						<div dangerouslySetInnerHTML={{__html: plan.terms}}>
						</div>
					</div>
				</Col>
				</Row>
			<ToastContainer/>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Body>
					<div className="text-center">
						<p>Processing Payment...</p>
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</Container>)
}

export default Plan;
