import {useEffect, useState} from "react";
import {useAuth} from "../../AuthUser";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import VideoUpload from "./VideoUpload";
import API from "../../api";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProgressTracker from "../../components/Admin/ProgressTracker";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import ProfileImage from "../../components/ProfileImage";
import moment from "moment";


function AdminUser() {
	let auth = useAuth();
	let navigate = useNavigate();
	let { userId } = useParams();

	const [user, setUser] = useState([])
	const [isLoading, setLoading] = useState(true)

	const fetchData = async () => {
		const response = await API.get(`admin/users/${userId}`);
		const data = response.data;
		console.log(response);
		setUser(data);
	}

	useEffect(() => {
		setLoading(true);
		fetchData().then().finally(() => setLoading(false))
	}, [])

	const editProfile = async () => {
			navigate(`/admin/users/edit/${user.user.id}`);
		}

		const createLesson = async () => {
			navigate(`/admin/users/${user.user.id}/createLesson`);
		}

	if(!auth.user) {
		return <Navigate to="/signin" replace/>
	}
	if (!auth.user.admin) {
		return <Navigate to="/" replace />;
	}

	const planTypeTemplate = (plan) => {
		if (plan === 'premier') {
			return <Badge bg="light" text="dark"><span className="text-capitalize">{plan}</span></Badge>
		}
		if (plan === 'plus') {
			return <Badge bg="secondary"><span className="text-capitalize">Premier+</span></Badge>
		}
		if (plan === 'pro') {
			return <Badge bg="warning"><span className="text-capitalize">{plan}</span></Badge>
		}
		return <Badge bg="light" text="dark"><span className="text-capitalize">{plan || 'Free'}</span></Badge>
	}

	const isInstructorTemplate = (is) => {
		if (is) {
			return <Badge bg="info" text="dark"><span className="text-capitalize">Instructor</span></Badge>
		}
	}

	const onRowSelect = (event) => {
		navigate(`/lesson/${event.data.id}`)
	};

	const submittedDateTemplate = (lesson) => {
		return moment.utc(lesson.created_at, 'YYYY-MM-DD').format('YYYY-MM-DD');
	}

	return (
		isLoading ? <div style={{textAlign: 'center'}}> <Spinner animation="border" /></div> :
			<>
			<div className="mt-3">
				<Row>
					<Col>
						<div className="mb-2">
					<Card>
						<Card.Body>
							<div className="d-flex flex-row p-3 gap-3">
								<ProfileImage size={60} textSizeRatio={2.75} text={user.user.email}/>
								<div className="w-100">
									<div className="d-flex justify-content-between align-items-center">
										<div className="d-flex flex-row align-items-center">
											<span className="me-2">{user.user?.firstName} {user.user?.lastName} {planTypeTemplate(user.user.planType)}</span>
											<span className="me-2">{isInstructorTemplate(user.user.isInstructor)}</span>
										</div>
										<Button onClick={() => editProfile()} variant="primary">Edit Profile</Button>
									</div>
									<div>
										<span className="text-muted">{user.user.email}</span>
									</div>
								</div>
							</div>
						</Card.Body>
					</Card>
						</div>
					</Col>
				</Row>
					<Row>
						<Col lg={4} md={3} sm={12}>
							<div className="mb-2">
							<Card>
								<Card.Body>
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div>
											<Card.Title>User Details</Card.Title>
											<hr/>
											<dl className="row">
												<dt className="col-sm-5">Full Name:</dt>
												<dd className="col-sm-7">{user.user.firstName} {user.user.lastName}</dd>
												<dt className="col-sm-5">Email:</dt>
												<dd className="col-sm-7">{user.user.email}</dd>
												<dt className="col-sm-5">User Type:</dt>
												<dd className="col-sm-7">{user.user.admin ? 'Admin' : 'User'}</dd>
												<dt className="col-sm-5">Instructor:</dt>
												<dd className="col-sm-7">{user.user.isInstructor ? 'Yes' : 'No'}</dd>
												<dt className="col-sm-5">Plan Type:</dt>
												<dd className="col-sm-7"><span className="text-capitalize">{user.user.planType || 'Free'}</span></dd>
												<dt className="col-sm-5">Subscription Exp Date:</dt>
												<dd className="col-sm-7">{user.user.subscriptionExpireDate ? moment(user.user.subscriptionExpireDate).format('MM/DD/YYYY') : 'none' }</dd>
												<dt className="col-sm-5">Lessons Remaining:</dt>
												<dd className="col-sm-7">{user.remainingLessonCount}</dd>
												<dt className="col-sm-5">Last Lesson Date:</dt>
												<dd className="col-sm-7">{user.lastLessonDate || 'none'}</dd>
											</dl>
										</div>
									</div>
								</Card.Body>
							</Card>
							</div>
							<Col>
								<Card>
									<Card.Body>
										<Card.Title>Progress Tracker</Card.Title>
										<hr/>
										<ProgressTracker
											handicaps={user.handicap}
										/>
									</Card.Body>
								</Card>

							</Col>
						</Col>
						<Col lg={8} md={9} sm={12}>
							<Tabs
								defaultActiveKey="lessons"
								id="justify-tab-example"
								className="mb-3"
								//justify
							>
								<Tab eventKey="lessons" title="Lessons">
											<div>
													<div className="p-2 mb-3 d-flex justify-content-between align-items-center">
														<div className="d-flex flex-row align-items-center">
															<strong>Current Handicap:</strong>{user.lastHandicap}
														</div>
														<Button onClick={() => createLesson()} variant="success" size="sm"><i className="ba bi-plus"/> Create Lesson</Button>
													</div>
												<DataTable
													style={{fontSize: '14px'}}
													value={user.lessons}
													rows={5}
													paginator
													rowsPerPageOptions={[5, 25, 50]}
													tableStyle={{minWidth: '50rem'}}
													selectionMode="single"
													onRowSelect={onRowSelect}
												>
													<Column field="id" header="Lesson ID" />
													<Column field="status" header="Status" />
													<Column field="instructor_notes" header="Instructor Notes" />
													<Column field="created_at" header="Submitted Date" dataType="date" body={submittedDateTemplate}/>
												</DataTable>
											</div>
								</Tab>
								<Tab eventKey="wizard" title="Wizard">
									<div className="p-2">
										<dl className="row">
											<dt className="col-sm-5">Bunker Play:</dt>
											<dd className="col-sm-7">{user.wizard.bunkerPlay}</dd>
											<dt className="col-sm-5">Chipping:</dt>
											<dd className="col-sm-7">{user.wizard.chipping}</dd>
											<dt className="col-sm-5">Course Management:</dt>
											<dd className="col-sm-7">{user.wizard.courseManagement}</dd>
											<dt className="col-sm-5">Driver Accuracy:</dt>
											<dd className="col-sm-7">{user.wizard.driverAccuracy}</dd>
											<dt className="col-sm-5">Driver Distance:</dt>
											<dd className="col-sm-7">{user.wizard.driverDistance}</dd>
											<dt className="col-sm-5">Equipment</dt>
											<dd className="col-sm-7">{user.wizard.equipment}</dd>
											<dt className="col-sm-5">Fairways, Woods and Hybrids:</dt>
											<dd className="col-sm-7">{user.wizard.fairwaysWoodsHybrids}</dd>
											<dt className="col-sm-5">Frustrations:</dt>
											<dd className="col-sm-7">{user.wizard.frustrates}</dd>
											<dt className="col-sm-5">Goals:</dt>
											<dd className="col-sm-7">{user.wizard.goals}</dd>
											<dt className="col-sm-5">Injuries:</dt>
											<dd className="col-sm-7">{user.wizard.injuries}</dd>
											<dt className="col-sm-5">Iron Play:</dt>
											<dd className="col-sm-7">{user.wizard.ironPlay}</dd>
											<dt className="col-sm-5">Pitching:</dt>
											<dd className="col-sm-7">{user.wizard.pitching}</dd>
											<dt className="col-sm-5">Time Played:</dt>
											<dd className="col-sm-7">{user.wizard.practice}</dd>
											<dt className="col-sm-5">Putting:</dt>
											<dd className="col-sm-7">{user.wizard.putting}</dd>
											<dt className="col-sm-5">Rounds Played:</dt>
											<dd className="col-sm-7">{user.wizard.rounds}</dd>
											<dt className="col-sm-5">Training Aids Used:</dt>
											<dd className="col-sm-7">{user.wizard.training}</dd>
										</dl>
									</div>
								</Tab>
								<Tab eventKey="subscriptions" title="Subscriptions">
									Coming soon...
								</Tab>
							</Tabs>


						</Col>
					</Row>
			</div>
		</>
	);
}

export default AdminUser;
