import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Card} from "react-bootstrap";
import PricingButton from "./components/PricingButton";
import {Helmet} from "react-helmet";
import {useAuth} from "./AuthUser";

function Pricing() {
	const { setUser, user } = useAuth();

	return (
		<>
			<Helmet>
				<title>Pricing | MyGolfDNA.com</title>
				<meta name="description" content="Pricing and Plans"/>
			</Helmet>
		<Container>
			<Row className="justify-content-center">
					<Col lg={5} md={12} sm={12}>
						<div className="" style={{padding:'56.25% 0 0 0',position:'relative'}}>
							<iframe
								src="https://player.vimeo.com/video/827001005?h=521d44fbfb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
								frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
								style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} title="Why mygolfdna and pocket pro mixed for sales page"/>
						</div>
					</Col>
					<h1 className="text-center text-white my-4" style={{fontSize: "3.75rem", lineHeight: 1, fontWeight: 800}}>Get Access Now</h1>
			</Row>
			<Row  className="justify-content-center">
				<Col lg={3} md={12} sm={12}>
					<Card className="bg-dark">
						<Card.Body>
							<div className="d-grid gap-2">
								<Card.Title className="text-secondary monument-font">DNA PREMIER</Card.Title>
								<div className="text-white">
									<ul className="list-unstyled">
										<li><i className="bi bi-check-circle text-success"/> 2 virtual lessons per month</li>
										<li><i className="bi bi-check-circle text-success"/> Live DNA consult at sign up</li>
										<li><i className="bi bi-check-circle text-success"/> Access to entire video library</li>
										<li><i className="bi bi-check-circle text-success"/> Access to pocket pro</li>
										<br/>
										<br/>
										<br/>
										<br/>
									</ul>
								</div>
								<div className="d-flex align-items-center gap-1">
									<div className="text-white h1">
										$67
									</div>
									<small className="text-white">/month</small>
								</div>
								<PricingButton
									href="/premier"
									plan="premier"
								>
									Get Started Now
								</PricingButton>


							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={3} md={12} sm={12}>
					<Card className="bg-dark border-secondary">
						<Card.Body>
							<div className="d-grid gap-2">
								<Card.Title className="text-white-50 monument-font">DNA PREMIER+</Card.Title>
								<div className="text-white">
									<ul className="list-unstyled">
										<li><i className="bi bi-check-circle text-success"/> UNLIMITED virtual lessons</li>
										<li><i className="bi bi-check-circle text-success"/> Live DNA consult at sign up</li>
										<li><i className="bi bi-check-circle text-success"/> 15 minutes of pocket pro time</li>
										<li><i className="bi bi-check-circle text-success"/> Access to entire video library</li>
										<li><i className="bi bi-check-circle text-success"/> Member only Q&A forum</li>
										<li><i className="bi bi-check-circle text-success"/> Free access to all webinars</li>
										<br/>
										<br/>
									</ul>
								</div>
								<div className="d-flex align-items-center gap-1">
									<div className="text-white h1">
										$167
									</div>
									<small className="text-white">/month</small>
								</div>

								<PricingButton
									href="/premier-plus"
									plan="plus"
								>
									Get Started Now
								</PricingButton>


							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={3} md={12} sm={12}>
					<Card className="bg-dark">
						<Card.Body>
							<div className="d-grid gap-2">
								<Card.Title className="text-warning monument-font">DNA PRO</Card.Title>
								<div className="text-white">
									<ul className="list-unstyled">
										<li><i className="bi bi-check-circle text-success"/> UNLIMITED virtual lessons</li>
										<li><i className="bi bi-check-circle text-success"/> 1 hour of pocket pro time</li>
										<li><i className="bi bi-check-circle text-success"/> Live DNA consult at sign up</li>
										<li><i className="bi bi-check-circle text-success"/> Pocket pro priority booking</li>
										<li><i className="bi bi-check-circle text-success"/> Weekly practice plans</li>
										<li><i className="bi bi-check-circle text-success"/> Access to entire video library</li>
										<li><i className="bi bi-check-circle text-success"/> Member only forum</li>
										<li><i className="bi bi-check-circle text-success"/> Free access to all webinars</li>
									</ul>
								</div>
								<div className="d-flex align-items-center gap-1">
									<div className="text-white h1">
										$397
									</div>
									<small className="text-white">/month</small>
								</div>

								<PricingButton
									href="/pro"
									plan="pro"
								>
									Get Started Now
								</PricingButton>


							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
			</>
	)
}

export default Pricing;
